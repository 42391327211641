<template>
  <div class="container" style="margin-top: 80px;min-height: 500px;">
    <div class="recruitinfo-title">
      <div style="margin-bottom: 20px;" class="dis-bet-cen">
        <span class="font-title">{{item.title}}</span>
        <span class="font-content red-font">{{item.salary ? item.salary : ''}}</span>
      </div>
      <div class="dis-bet-cen">
        <span class="font-content">{{item.provice ? item.provice : '暂无地区' }}  丨  {{item.education}}  丨  {{item.experience}}</span>
        <span>发布日期：{{item.publishtime}}</span>
      </div>
    </div>
    <div class="recruitinfo-content">
      <div class="font-title" style="margin-bottom: 10px;">职位描述</div>
      <div class="font-p">
        {{item.description}}
      </div>
    </div>
    <!-- 更多 -->
    <div class="more-list">
      <div class="font-title" style="padding: 20px;  border-bottom: 1px solid #ebebeb;">该公司还在招</div>
      <div class="recruitinfo-title" style="border-bottom: 1px solid #ebebeb;" @click="companyAchievementNameClick(item)" v-for="(item, index) of list" :key="index">
        <div style="margin-bottom: 20px;" class="dis-bet-cen">
          <span class="font-title">{{item.title}}</span>
          <span class="font-content red-font">{{item.salary ? item.salary : ''}}</span>
        </div>
        <div class="dis-bet-cen">
          <span class="font-content">{{item.provice ? item.provice : '暂无地区' }}  丨  {{item.education}}  丨  {{item.experience}}</span>
          <span>发布日期：{{item.publishtime}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  data () {
    return {
      item: {},
      list: []
    }
  },
  mounted () {
    const vm = this
    const itemStr = sessionStorage.getItem('recruitinfo-mes-item')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      vm.item = item
      vm.init()
    }
  },
  methods: {
    companyAchievementNameClick (e) {
      const vm = this
      vm.item = e
      vm.init()
    },
    init () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyOperatingState/recruitmentInformationList',
        dateBackgroundRequestType: 'get',
        data: {
          qcc_company_id: vm.item.companyId,
          pageNum: 1,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          console.log(vm.item.id)
          res.list.forEach(item => {
            if (item.id !== vm.item.id) {
              if (item.area) {
                const lar = item.area.split('_')
                item.provice = lar[0] + (lar[1] ? '-' + lar[1] : '')
              }
              item.publishtime = item.publishtime ? item.publishtime.substring(0, 10) : '-'
              arr.push(item)
            }
          })
          vm.list = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.recruitinfo-title {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
}
.recruitinfo-content {
  background-color: #fff;
  padding: 20px;
}
.font-title {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  text-align: left;
}
.font-content {
  font-size: 16px;
  color: #999999;
}
.red-font {
  color: red;
}
.font-p {
  color: #999;
  text-align: left;
  font-size: 16px;
}
.more-list {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
